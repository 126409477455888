import React from "react";

import async from "../components/Async";

import {
  List,
  Sliders,
  Users,
  Code,
  Settings as SettingsIcon
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ChangePassword = async(() => import("../pages/auth/ChangePassword"));
const ResetPasswordSuccess = async(() => import("../pages/auth/ResetPasswordSuccess"));

const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboards components
const Default = async(() => import("../pages/dashboards"));

const Settings = async(() => import("../pages/settings"));
const Configurations = async(() => import("../pages/configurations"));

const dashboardsRoutes = {
  id: "Shop",
  path: "/",
  // header: "Main",
  icon: <Sliders />,
  component: Default,
  children: null
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/change-password",
      name: "Change Password",
      component: ChangePassword
    },
    {
      path: "/auth/reset-password-success",
      name: "Reset Password Success",
      component: ResetPasswordSuccess
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

// const userRoute = {
//   id: "Shop",
//   path: "/shop",
//   icon: <UserPlus />,
//   children: null,
//   component: User,
// };


// const customerRoute = {
//   id: "Customer",
//   path: "/customer",
//   icon: <Users />,
//   children: null,
//   component: Customer,
// };

const settingsRoute = {
  id: "Settings",
  path: "/settings",
  icon: <SettingsIcon />,
  children: null,
  component: Settings,
};

const configRoute = {
  id: "Configurations",
  path: "/configurations",
  icon: <Code />,
  children: null,
  component: Configurations,
};

export const auth = [authRoutes];

export const dashboard = [
  dashboardsRoutes,
  // userRoute,
  // customerRoute,
  configRoute,
  settingsRoute
];

export default [
   dashboardsRoutes,
  //  userRoute,
  //  customerRoute,
    configRoute,
   settingsRoute
];
