import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { withRouter } from 'react-router-dom';

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import LocalStorageService from "./../services/LocalStorageService";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import { setBreadcrumb } from "../redux/actions/breadcrumbActions";
import { connect } from 'react-redux';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    max-width: calc(100% - 260px);
  }
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  position: relative;
  flex: 1;
  background: ${props => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const localStorageService = LocalStorageService.getService();

class Dashboard extends React.Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  componentDidMount() {
    // this.props.setBreadcrumb('Dashboard')
    if (localStorageService.getAccessToken()) {
      // this.props.history.push('/')
    } else {
      this.props.history.push('/auth/sign-in')
    }
  }

  render() {
    const { children, routes, width } = this.props;

    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Hidden mdUp implementation="js">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </Drawer>
        <AppContent>
          <Header onDrawerToggle={this.handleDrawerToggle} />
          <MainContent p={isWidthUp("lg", width) ? 10 : 8}>
            {children}
          </MainContent>
          <Footer />
        </AppContent>
        <Settings />
      </Root>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    breadcrumb: store.breadcrumbReducer
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setBreadcrumb: (header) => {
      dispatch(setBreadcrumb(header));
    }
  }
}

//export default compose( withWidth(), withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));