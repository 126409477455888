export default {
  queryRouteList: '/routes',
  // User
  queryUserInfo: 'GET /user/:id',
  checkUser: 'GET /auth/user/:id',
  getUserInfor: 'GET /user/:id',
  queryListUser: 'GET /user',
  updateUserInfo: 'PUT /user/:id',
  changeUserPassword: 'POST /change-password',
  logout: 'POST /auth/logout',
  login: 'POST /auth/login',
  forgetPassword: 'POST /forgot-password',
  createUser: 'POST /user',
  checkTokenVaid: '/forgot-password',
  registerNewPassword: 'PUT /forgot-password/:id',
  activeUser: 'POST /active-user',
  changePassword: 'PUT /forgot-password/:id',
  checkTokenValidate: 'GET /forgot-password',
  changePasswordU: 'POST /change-password',
  deleteUser: 'DELETE /user/:id',
  getListShop: 'GET /shop',
  activeShop: 'PUT /shop/:id',
  updateShop: 'PUT /shop/:id',
  createShop: 'POST /shop',
  getMyAdmin: 'GET /user',
  updateMyAdmin: 'POST /user',
  getAdminSetting: 'GET /admin-setting',
  updateAdminSetting: 'POST /admin-setting',
  creatAccountAdmin: 'POST /user',
  updateAccountAdmin: 'PUT /user/:id',
  getGoogleBusiness: 'GET /get-google-business',
  getPackagesList: 'GET /package?is_admin=1',
  createPackage: 'POST /package?is_admin=1',
  updatePackage: 'PUT /package/:id',
}
