import store from 'store'

import APIFunction from './../services'
// import LocalStorageService from "./../services/LocalStorageService";

// get user store
export const getUserStore = () => {
  return store.get('auth')
}

// get user info
export const queryUserInfo = (params) => {
  return APIFunction.queryUserInfo(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// update user info
export const updateUserInfo = (params) => {
  return APIFunction.updateUserInfo(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get user info
export const changeUserPassword = (params) => {
  return APIFunction.changeUserPassword(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get user store
export const removeUserStore = () => {
  return store.remove('auth')
}

// const localStorageService = LocalStorageService.getService();
// check validate
export const checkTokenValidate = (token) => {
  return APIFunction.checkTokenValidate({ token: token, is_admin: 1 })
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}
// change Password
export const changePasswordUser = (token, password) => {
  return APIFunction.changePassword({
    id: token,
    username: 'clone',
    password: password,
    is_admin: 1,
  })
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}
// change Password
export const changePasswordU = (
  old_password,
  new_password,
  confirm_new_password
) => {
  return APIFunction.changePasswordU({
    old_password: old_password,
    new_password: new_password,
    confirm_new_password: confirm_new_password,
    is_admin: 1,
  })
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get user info
export const getListStatus = (params) => {
  return APIFunction.listStatus(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get report
export const dashboardReport = (params) => {
  return APIFunction.dashboardReport(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// query Post
export const queryPostList = (params) => {
  return APIFunction.queryPostList(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// create Post
export const createPost = (params) => {
  return APIFunction.createPost(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// view Post
export const viewPost = (params) => {
  return APIFunction.viewPost(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// view Post
export const actionPost = (params) => {
  return APIFunction.actionPost(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// update Post
export const updatePost = (params) => {
  return APIFunction.updatePost(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

export const deletePost = (params) => {
  return APIFunction.deletePost(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

export const getListShop = (params) => {
  return APIFunction.getListShop(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// active Shop
export const activeShop = (params) => {
  return APIFunction.activeShop(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// update Shop
export const updateShop = (params) => {
  return APIFunction.updateShop(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

//create Shop
export const createShop = (params) => {
  return APIFunction.createShop(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get user info
export const getMyAdmin = (params) => {
  return APIFunction.getMyAdmin(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get user info
export const updateMyAdmin = (params) => {
  return APIFunction.updateMyAdmin(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// getAdminSetting
export const getAdminSetting = (params) => {
  return APIFunction.getAdminSetting(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// getAdminSetting
export const updateAdminSetting = (params) => {
  return APIFunction.updateAdminSetting(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// getAdminSetting
export const creatAccountAdmin = (params) => {
  return APIFunction.creatAccountAdmin(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get user info
export const updateAccountAdmin = (params) => {
  return APIFunction.updateAccountAdmin(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// update user info
export const getGoogleBusiness = (params) => {
  return APIFunction.getGoogleBusiness(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// get packages list
export const getPackagesList = (params) => {
  return APIFunction.getPackagesList(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// create package
export const createPackage = (params) => {
  return APIFunction.createPackage(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}

// update package
export const updatePackage = (params) => {
  return APIFunction.updatePackage(params)
    .then((result) => {
      return result
    })
    .catch((error) => {
      return error
    })
}
